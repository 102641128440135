<template>
    <div class="page">
        <div class="top">
            <div class="logo">
                <img src="../../../assets/1123.png"/>
                <div class="shop">购买记录</div>
            </div>
        </div>
        <div class="content">
            <div class="list" v-for="(item,index) in list" v-bind:key="index">
                <div class="modalTitle">
                    <div style="float: left;margin: 0 0 0 80px;"><img :src='item.mobileLogo' style="width: 32px;height: 32px;position: relative;top: 10px;"/></div>
                    <div class="name">{{item.name}}</div>
                </div>
                <div class="modelContent">{{item.blurb}}</div>
                <div class="sumbit">
                    <div class="in">进入系统</div>
                    <div class="see">查看商品</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {list} from '@/api/member'
    export default {
        name: "index",
        data() {
            return {
                list: []
            }
        },
        created: function () {
            this.getData()
        },
        methods: {
            //获取应用列表
            getData() {
                let that = this;
                let data = {
                    page: 1,
                    limit: 10000
                }
                list(data).then(res => {
                    that.list = res.rows;
                })
            },
        }
    }
</script>

<style scoped>
    .page {
        width: 100%;
    }
    .top{
        background-color: #ffffff;
        height: 180px;
        border-bottom: 10px solid #288aff;
    }
    .logo{
        width: 1200px;
        margin: 0 auto;
        text-align: center;
        padding-top: 60px;
    }
    .shop{
        font-size: 20px;
        color: #288aff;
        font-family: SourceHanSansCN-Regular;
        text-align: right;
    }
    .content{
        width: 1200px;
        border: 1px solid #f4f7f9;
        height: auto!important;
        min-height: 1080px;
        margin: 10px auto;
        background-color: #ffffff;
        padding: 30px 15px;
    }
    .list{
        border: 1px solid #def3f0;
        width: 358px;
        height: 280px;
        margin: 50px 15px 0 15px;
        float: left;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .modalTitle{
        text-align: center;
        width: 100%;
        height: 76px;
        line-height: 76px;
        background-color: #f4f7f9;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .name{
        line-height: 76px;
        float: left;
        font-size: 22px;
        color: #222222;
    }
    .modelContent{
        font-size: 16px;
        color: #222222;
        line-height: 40px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        float: left;
        padding: 20px 40px;
        height: 70px;
    }
    .sumbit{
        float: left;
        padding: 20px 40px;
    }
    .in{
        background-color: #fb560a;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        width: 120px;
        height: 40px;
        line-height: 40px;
        float: left;
    }
    .see{
        background-color: #2580ff;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        width: 120px;
        height: 40px;
        line-height: 40px;
        float: right;
        margin-left: 38px;
    }
</style>
